import React, { useState, useEffect, useRef } from "react";

import clsx from "clsx";
import { navigate } from "gatsby";

import CouponForm from "~/components/Coupons/CouponForm";
import useCancelableFetch from "~/hooks/useCancelableFetch";
import usePermissions from "~/hooks/usePermissions";
import couponsAPI from "~/utils/api/v2/coupons";
import type { IExtendedCoupon } from "~/utils/interfaces/coupons";
import { CreateEditCoupon } from "~/utils/interfaces/coupons";

interface IProps {
  id: string;
}

const EditCoupon = ({ id }: IProps) => {
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const { isCouponAdmin } = usePermissions();
  const { successResponse, isLoading } = useCancelableFetch<IExtendedCoupon, any>(couponsAPI.retrieve, { id });

  const formRef = useRef();

  const submit = async () => {
    setIsSubmiting(true);
    const data = formRef.current?.getCouponData();
    const response = await couponsAPI.partialUpdate({ id, data });

    if ("error" in response) {
      alert(JSON.stringify(response.error.response.data));
    } else {
      alert("Cupón editado exitosamente");
      setIsSubmiting(false);
      navigate(`/coupons/${id}/`);
    }

    setIsSubmiting(false);
  };

  useEffect(() => {
    if (!isCouponAdmin) {
      navigate("/");
    }
  }, []);

  const startValues = successResponse;
  if (startValues) {
    delete startValues["id"];
    delete startValues["active"];
    delete startValues["insurance_allowed"];
  }

  return (
    <div>
      {isLoading ? (
        <div>Cargando...</div>
      ) : (
        <>
          <h1>Editar cupón</h1>
          <div className="bg-examedi-white-pure p-4 border rounded-md max-w-xl mx-auto my-4">
            <CouponForm
              ref={formRef}
              startingValues={startValues as CreateEditCoupon}
            />
            <div className="flex flex-row justify-center mt-4 gap-4">
              <button
                className={clsx(
                  "bg-examedi-blue-normal",
                  "text-examedi-white-pure text-sm",
                  "rounded-lg block p-2.5",
                  isSubmiting && "bg-examedi-gray-bg cursor-wait",
                )}
                onClick={submit}
                disabled={isSubmiting}
              >
                EDITAR
              </button>
              <button
                className="bg-examedi-gray-bg text-sm rounded-lg block p-2.5"
                onClick={() => {
                  navigate(`/coupons/${id}/`);
                }}
              >
                VOLVER
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditCoupon;
